<template>
  <div>
    <button
      class="btn"
      v-if="$store.state.currentRole == 'ADMIN'"
      style="background-color: #083628; color: white;"
      @click="showAddUserModal"
    >Send Marketing Email</button>
    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Send Marketing Email`"
            :visible.sync="addUserModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="userForm"
                :rules="rules"
                label-position="top"
                ref="userForm"
                class="demo-userForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Full Name" prop="fullname">
                      <el-input v-model="userForm.fullname"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Email" prop="email">
                      <el-input v-model="userForm.email"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Link" prop="phone">
                      <el-input v-model="userForm.phone"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item label="Time" prop="time">
                      <el-input v-model="userForm.time"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Date" prop="date">
                      <el-date-picker v-model="userForm.date"></el-date-picker>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="addUser('userForm')"
                  >Send Marketing Email</el-button>
                </el-form-item>
              </el-form>
              <br />
              <div class="text-center">
                <div class="text-center">
                  <small>© 2023 Ihamba Adventures | All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      bookings: [],
      users: [],
      userEditId: "",
      selectedUserPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 }
      ],
      userPhotoFileLists: [],
      countries: [],

      options: [
        {
          value: "AGENT",
          label: "Agent"
        },
        {
          value: "ADMIN",
          label: "Admin"
        }
      ],

      addUserModal: false,
      editUserModal: false,
      years: "",
      year_numbers: [],
      value: "",
      loadingError: false,
      submitting: false,
      course: "",
      userForm: {
        fullname: "",
        email: "",
        time: "",
        date: "",
        phone: ""
      },

      rules: {
        fullname: [
          { required: true, message: "Full Name is required", trigger: "blur" }
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" }
        ],
        time: [
          {
            required: true,
            message: "time is required",
            trigger: "change"
          }
        ],

        date: [
          {
            required: true,
            message: "date is required",
            trigger: "change"
          }
        ],
        phone: [
          {
            required: true,
            message: "phone is required",
            trigger: "change"
          }
        ]
      }
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    showAddUserModal() {
      this.addUserModal = true;
    },

    async addUser(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("fullname", this.userForm.fullname);
            formData.append("date", this.userForm.date);
            formData.append("email", this.userForm.email);
            formData.append("time", this.userForm.time);
            formData.append("phone", this.userForm.phone);

            let response = await this.$http.post(
              `safari_tours/send_em`,
              formData,
              {
                onUploadProgress: progressEvent => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                }
              }
            );
            if (
              response.data.success &&
              response.data.message == "EMAIL_SENT_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();

              this.$notify({
                title: "Success",
                message: "Email sent successfully",
                type: "success"
              });
              this.addUserModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error"
              });
            }
            this.$notify({
              title: "Unable to add user",
              message: "An unexpected Error occurred, please try again",
              type: "error"
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>
  
  <style>
</style>